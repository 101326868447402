<template>
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     viewBox="0 0 238 48" style="enable-background:new 0 0 238 48;" xml:space="preserve">

    <path class="st0" d="M59.1,14.1h3.7V35h-3.7V14.1z M67.1,14.1h5.7l5.5,14.6l5.6-14.6h5.6V35H86V17.6h-0.1L79.6,35H77l-6.4-17.4h-0.1
V35H67L67.1,14.1z M94,14.1h5.7l5.6,14.6l5.6-14.6h5.6V35h-3.5V17.6h-0.1L106.4,35h-2.6l-6.3-17.4h-0.1V35h-3.5L94,14.1z
 M119.6,24.6c0-1.6,0.3-3.1,0.8-4.6c0.5-1.3,1.3-2.5,2.3-3.5c1-1,2.2-1.7,3.5-2.2c1.4-0.5,2.9-0.8,4.4-0.8c1.5,0,3,0.2,4.5,0.7
c1.3,0.5,2.5,1.2,3.5,2.2c1,1,1.8,2.2,2.3,3.5c0.6,1.4,0.8,3,0.8,4.5c0,1.5-0.3,3-0.8,4.5c-0.5,1.3-1.3,2.5-2.3,3.5
c-1,1-2.2,1.8-3.5,2.3c-1.4,0.6-2.9,0.8-4.5,0.8c-1.5,0-3-0.2-4.4-0.8c-1.3-0.5-2.5-1.2-3.5-2.2c-1-1-1.8-2.1-2.3-3.4
C119.8,27.6,119.5,26.1,119.6,24.6z M123.5,24.4c0,1.1,0.2,2.1,0.5,3.1c0.3,0.9,0.8,1.7,1.5,2.5c0.6,0.7,1.4,1.2,2.3,1.6
c0.9,0.4,1.9,0.6,2.9,0.6c1,0,2-0.2,3-0.6c0.9-0.4,1.6-0.9,2.3-1.6c0.6-0.7,1.1-1.5,1.4-2.5c0.4-1,0.5-2,0.5-3.1c0-1-0.2-2-0.5-2.9
c-0.3-0.9-0.8-1.7-1.4-2.4c-0.6-0.7-1.4-1.2-2.3-1.6c-0.9-0.4-1.9-0.6-3-0.6c-1,0-2,0.2-2.9,0.6c-0.9,0.4-1.6,0.9-2.3,1.6
c-0.6,0.7-1.1,1.5-1.4,2.4C123.6,22.4,123.5,23.4,123.5,24.4z M149.1,17.4h-6.4v-3.4h16.5v3.4h-6.4V35h-3.7V17.4z M160.2,24.6
c0-1.6,0.3-3.1,0.8-4.6c0.5-1.3,1.3-2.5,2.3-3.5c1-1,2.2-1.7,3.5-2.2c1.4-0.5,2.9-0.8,4.4-0.8c1.5,0,3,0.2,4.5,0.7
c1.3,0.5,2.5,1.2,3.5,2.2c1,1,1.8,2.2,2.3,3.5c0.6,1.4,0.9,3,0.8,4.5c0,1.5-0.3,3-0.8,4.4c-0.5,1.3-1.3,2.5-2.3,3.5
c-1,1-2.2,1.8-3.5,2.3c-1.4,0.6-2.9,0.8-4.5,0.8c-1.5,0-3-0.2-4.4-0.8c-1.3-0.5-2.5-1.2-3.5-2.2c-1-1-1.8-2.2-2.3-3.5
C160.4,27.6,160.2,26.1,160.2,24.6z M164.1,24.4c0,1.1,0.2,2.1,0.5,3.1c0.3,0.9,0.8,1.7,1.5,2.5c0.6,0.7,1.4,1.2,2.2,1.6
c0.9,0.4,1.9,0.6,2.9,0.6s2-0.2,2.9-0.6c0.9-0.4,1.6-0.9,2.3-1.6c0.6-0.7,1.1-1.5,1.5-2.5c0.4-1,0.5-2,0.5-3.1c0-1-0.2-2-0.5-2.9
c-0.3-0.9-0.8-1.7-1.5-2.4c-0.6-0.7-1.4-1.2-2.3-1.6c-1.9-0.8-4-0.8-5.9,0c-0.9,0.4-1.6,0.9-2.2,1.6c-0.6,0.7-1.1,1.5-1.5,2.4
C164.2,22.4,164.1,23.4,164.1,24.4z M185.4,14.1h7c1,0,1.9,0.1,2.9,0.3c0.9,0.2,1.7,0.5,2.5,1c0.7,0.5,1.3,1.1,1.7,1.9
c0.4,0.9,0.7,1.9,0.6,2.9c0.1,1.1-0.2,2.2-0.7,3.2c-0.4,0.8-1.1,1.4-1.8,1.9c-0.8,0.5-1.7,0.8-2.7,0.9c-1,0.2-2.1,0.2-3.2,0.2h-2.6
V35h-3.7L185.4,14.1z M191.4,23.1c0.5,0,1.1,0,1.6-0.1c0.5,0,1.1-0.2,1.6-0.4c0.5-0.2,0.9-0.5,1.2-0.9c0.3-0.5,0.5-1.1,0.5-1.7
c0-0.5-0.1-1.1-0.4-1.5c-0.3-0.4-0.6-0.7-1.1-0.9c-0.5-0.2-0.9-0.4-1.4-0.4c-0.5-0.1-1-0.1-1.5-0.1h-2.7v5.9H191.4z M197.8,32.8
c0-0.3,0.1-0.6,0.2-0.9c0.1-0.3,0.3-0.5,0.5-0.7c0.2-0.2,0.5-0.4,0.8-0.5c0.3-0.1,0.6-0.2,0.9-0.2c0.6,0,1.2,0.2,1.7,0.6
c0.2,0.2,0.4,0.4,0.5,0.7c0.1,0.3,0.2,0.6,0.2,0.9c0,0.3-0.1,0.6-0.2,0.9c-0.1,0.3-0.3,0.5-0.5,0.7c-0.2,0.2-0.5,0.4-0.8,0.5
c-0.3,0.1-0.6,0.2-0.9,0.2c-0.3,0-0.6-0.1-0.9-0.2c-0.3-0.1-0.5-0.3-0.8-0.5c-0.2-0.2-0.4-0.4-0.5-0.7
C197.8,33.5,197.7,33.2,197.8,32.8L197.8,32.8z M206.4,14h3.7v17.6h8.9V35h-12.6L206.4,14z M238,27.2c0,1.2-0.2,2.3-0.6,3.4
c-0.4,1-1,1.9-1.8,2.6c-0.8,0.7-1.7,1.3-2.6,1.6c-2.2,0.8-4.5,0.8-6.7,0c-1-0.4-1.9-0.9-2.7-1.6c-0.8-0.7-1.4-1.6-1.8-2.6
c-0.4-1.1-0.6-2.2-0.6-3.4V14.1h3.7v13.1c0,0.5,0.1,1.1,0.2,1.6c0.2,0.6,0.4,1.1,0.8,1.5c0.4,0.5,0.9,0.9,1.4,1.2
c0.7,0.3,1.5,0.5,2.2,0.5s1.5-0.2,2.2-0.5c0.6-0.3,1.1-0.7,1.4-1.2c0.4-0.5,0.6-1,0.8-1.5c0.2-0.5,0.2-1.1,0.2-1.6V14.1h3.7V27.2z"
    />
    <path class="st1" d="M5.5,2.5c-0.8,0-1.5,0.3-2.1,0.9C2.8,3.9,2.5,4.7,2.5,5.5v37.1c0,0.8,0.3,1.5,0.9,2.1c0.6,0.6,1.3,0.9,2.1,0.9
h37.1c0.8,0,1.5-0.3,2.1-0.9c0.6-0.6,0.9-1.3,0.9-2.1V5.5c0-0.8-0.3-1.5-0.9-2.1c-0.6-0.6-1.3-0.9-2.1-0.9H5.5z M5.5,0h37.1
c1.5,0,2.8,0.6,3.9,1.6c1,1,1.6,2.4,1.6,3.9v37.1c0,1.5-0.6,2.8-1.6,3.9c-1,1-2.4,1.6-3.9,1.6H5.5c-1.5,0-2.8-0.6-3.9-1.6
c-1-1-1.6-2.4-1.6-3.9V5.5C0,4,0.6,2.6,1.6,1.6C2.6,0.6,4,0,5.5,0z"/>
    <path class="st1" d="M33.7,34.1c0.5,0.6,1.2,1.1,1.9,1.2c0.8,0.2,1.6,0,2.3-0.4c0.6-0.5,1.1-1.2,1.2-1.9c0.2-0.8,0-1.6-0.4-2.3
L26.5,13.9c-0.3-0.4-0.6-0.7-1-0.9c-0.4-0.2-0.9-0.3-1.3-0.3c-0.5,0-0.9,0.1-1.3,0.3c-0.4,0.2-0.7,0.5-1,0.9L9.7,30.7
c-0.4,0.7-0.5,1.5-0.4,2.3s0.6,1.5,1.2,2c0.7,0.4,1.5,0.6,2.3,0.4c0.8-0.2,1.5-0.6,1.9-1.2l9.7-13.1L33.7,34.1z"/>
</svg>


</template>

<style type="text/css">
    .st0{fill:#1D1E1C;}
    .dark-layout .st0{fill:#FEFEFE;}
    .st1{fill:#0074c1;}
</style>

<!--<style type="text/css">-->
<!--.st0{fill-rule:evenodd;clip-rule:evenodd;fill:#2B6482;}-->
<!--.st1{fill:#2B6482;}-->
<!--</style>-->

<script>
import store from "@/store";

export default {
    name: 'Logo',
    computed: {
        colorMode(){
            return store.state.appConfig.layout.skin;
        },
    },
};


</script>
