<template>
    <div class="auth-wrapper auth-v2">
        <b-overlay
            :show="$store.state.app.shallShowOverlay"
            rounded="sm"
            :bg-color="'transparent'"
            :opacity="0.65"
            :fixed="true"
            :no-wrap="true"
            :z-index="10001"
            class="h-100 bg-transparent"
        />
        <b-row class="auth-inner m-0">
            <!-- /Brand logo-->

            <!-- Left Text-->
            <b-col
                lg="7"
                class="d-none d-lg-flex align-items-center p-5"
            >
                <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
                    <b-img
                        fluid
                        :src="imgUrl"
                        alt="Login V2"
                        class="ren-side-img"
                    />
                </div>
            </b-col>
            <!-- /Left Text-->

            <!-- Login-->
            <b-col
                lg="5"
                class="d-flex align-items-center auth-bg px-2 p-lg-5"
            >
              <div class="rl-picker">
                <v-select
                    v-model="locale"
                    :options="languages"
                    label="text"
                    :clearable="false"
                />
              </div>
                <b-col
                    sm="8"
                    md="6"
                    lg="12"
                    class="px-xl-2 mx-auto"
                >

                    <div class="text-center  mb-3">
                        <vuexy-logo class="w-50"/>
                    </div>

                    <b-card-title
                        title-tag="h2"
                        class="font-weight-bold mb-1 text-center"
                    >
                      {{ i18nT(`Connexion à votre compte`)}}
                    </b-card-title>
                    <b-card-text class="mb-2 text-center">
                        {{i18nT(`Créer de meilleures relations entre les propriétaires et les locataires !`)}}
                    </b-card-text>

                    <!-- form -->
                    <validation-observer ref="loginValidation">
                        <b-form
                            class="auth-login-form mt-2"
                            @submit.prevent
                        >
                            <!-- email -->
                            <b-form-group
                                label="Email"
                                label-for="login-email"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="Email"
                                    rules="required|email"
                                >
                                    <b-form-input
                                        id="login-email"
                                        v-model="userEmail"
                                        :state="errors.length > 0 ? false:null"
                                        name="login-email"
                                        placeholder="john@example.com"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>

                            <!-- forgot password -->
                            <b-form-group>
                                <div class="d-flex justify-content-between">
                                    <label for="login-password">{{i18nT(`Passe`)}}</label>
                                    <b-link :to="{name:'auth-forgot-password', query: {lang: locale.value}}">
                                        <small>{{ i18nT(`Mot de passe perdu?`)}}</small>
                                    </b-link>
                                </div>
                                <validation-provider
                                    #default="{ errors }"
                                    name="Passe"
                                    rules="required"
                                >
                                    <b-input-group
                                        class="input-group-merge"
                                        :class="errors.length > 0 ? 'is-invalid':null"
                                    >
                                        <b-form-input
                                            id="login-password"
                                            v-model="password"
                                            :state="errors.length > 0 ? false:null"
                                            class="form-control-merge"
                                            :type="passwordFieldType"
                                            name="login-password"
                                            placeholder="············"
                                        />
                                        <b-input-group-append is-text>
                                            <feather-icon
                                                class="cursor-pointer"
                                                :icon="passwordToggleIcon"
                                                @click="togglePasswordVisibility"
                                            />
                                        </b-input-group-append>
                                    </b-input-group>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>

                            <!-- checkbox -->
                            <b-form-group>
                                <b-form-checkbox
                                    id="remember-me"
                                    v-model="status"
                                    name="checkbox-1"
                                >
                                    {{i18nT(`Se souvenir de moi`)}}
                                </b-form-checkbox>
                            </b-form-group>

                            <!-- submit buttons -->
                            <b-button
                                type="submit"
                                variant="primary"
                                block
                                @click="validationForm"
                            >
                                {{i18nT(`Connexion`)}}
                            </b-button>
                        </b-form>
                    </validation-observer>

                    <b-card-text class="text-center mt-2">
                        <span>{{i18nT(`Pas de compte ?`)}} </span>
                        <b-link :to="{name:'auth-register', query: {lang: locale.value}}">
                            <span>&nbsp;{{i18nT(`Cliquez ici`)}}</span>
                        </b-link>
                    </b-card-text>

                    <!-- divider -->
                    <div class="divider my-2" v-if="false">
                        <div class="divider-text">
                            or
                        </div>
                    </div>

                    <!-- social buttons -->
                    <div class="auth-footer-btn d-flex justify-content-center" v-if="false">
                        <b-button
                            variant="facebook"
                            href="javascript:void(0)"
                        >
                            <feather-icon icon="FacebookIcon" />
                        </b-button>
                        <b-button
                            variant="twitter"
                            href="javascript:void(0)"
                        >
                            <feather-icon icon="TwitterIcon" />
                        </b-button>
                        <b-button
                            variant="google"
                            href="javascript:void(0)"
                        >
                            <feather-icon icon="MailIcon" />
                        </b-button>
                        <b-button
                            variant="github"
                            href="javascript:void(0)"
                        >
                            <feather-icon icon="GithubIcon" />
                        </b-button>
                    </div>
                </b-col>
            </b-col>
            <!-- /Login-->
        </b-row>
    </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import VuexyLogo from '@client/Logo.vue'
import {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BOverlay,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import vSelect from 'vue-select'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useAuth from "@/auth/useAuth";
import {dictToSelectArray} from "@core/utils/utils";

export default {
    components: {
        BRow,
        BCol,
        BLink,
        BFormGroup,
        BFormInput,
        BInputGroupAppend,
        BInputGroup,
        BFormCheckbox,
        BCardText,
        BCardTitle,
        BImg,
        BForm,
        BButton,
        BOverlay,
        VuexyLogo,
        ValidationProvider,
        ValidationObserver,
        vSelect,
    },
    mixins: [togglePasswordVisibility],
    data() {
        return {
            status: '',
            password: '',
            userEmail: '',
            sideImg: require('@client/images/login/login-v2.svg'),
            // validation rulesimport store from '@/store/index'
            required,
            email,
            locale: {text: 'French', value: 'fr'},
            languages : [],
        }
    },
    computed: {
        passwordToggleIcon() {
            return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },
        imgUrl() {
            if (store.state.appConfig.layout.skin === 'dark') {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.sideImg = require('@client/images/login/login-v2-dark.svg')
                return this.sideImg
            }
            return this.sideImg
        },
    },
    created() {
        this.getLanguages()

        localize('fr'); // TODO - needs to be added  in a higher level
    },
    methods: {

        validationForm() {
            this.$refs.loginValidation.validate().then(success => {
                if (success) {
                    let payload = {
                        email: this.userEmail,
                        password: this.password,
                        rememberMe: this.status,
                    }

                    this.$http.post('login', payload).then((response)=>{

                        if(response.data.data.auth_key) {


                            useAuth.setToken(response.data.data.auth_key)
                            useAuth.setLocale(this.locale.value)
                            // useAuth.setCurWorkspace(response.data.data.current_workspace)
                            // useAuth.setWorkspaces(response.data.data.menu.workspaces)
                            // useAuth.setSoftwares(response.data.data.menu.apps)
                            // useAuth.setCurrentSoftware(response.data.data.current_sotftware)
                            useAuth.setProfile(response.data.data.user)
                            useAuth.setUserType(response.data.data.user.LandlordStatus? 'landlord': 'tenant')
                            //useAuth.setUserPermissions(response.data.data.menu.user.Permissions)
                            if(response.data.data.user.LandlordID){
                              useAuth.setLandlordId(response.data.data.user.LandlordID);
                            }
                            localStorage.setItem(
                                'userData',
                                JSON.stringify(response.data.data.user)
                            )
                            //this.$store.commit('app/UPDATE_LOCAL_USER', response.data.data.menu.user)

                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.i18nT(`Welcome`) +` ${response.data.data.user.LandlordFirstName}` + ` ${response.data.data.user.LandlordLastName}`,
                                    icon: 'CoffeeIcon',
                                    variant: 'success',
                                    text: this.i18nT(`Connexion réussie.`)
                                }
                            })

                            this.$router.push({name: 'tenants'}); //TODO - change to dashboard when ready

                        }
                    })
                    .catch((err)=>{

                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: this.errorMessage(err),
                                icon: 'CoffeeIcon',
                                variant: 'error',
                                text: this.i18nT(`Please try again later.`)
                            }
                        })

                        console.log("We got an error: ", err);
                    });

                }
            })
        },

        getLanguages() {
            this.$http.get(`languages`). then(({data}) => {
              this.languages = dictToSelectArray(data.data.languages)
              if(this.$route.query.lang) {
                this.locale = this.languages.find(c => c.value === this.$route.query.lang)
              }
                //TODO - adjust when we set up change language inside the app, not just from the dropdown in login menu
                if(useAuth.getLocale()){
                    this.locale = this.languages.find(c => c.value === useAuth.getLocale())
                }
          })
        },

    },
    watch: {
      locale: {
        handler: function (newVal, oldVal) {
          if(newVal && newVal.value !== 'undefined' && newVal.value !== null && newVal.value !== oldVal.value) {
            useAuth.setLocale(newVal.value)
            this.changeLang(newVal.value) // change the language TODO in main.js
            history.pushState({}, null, `${this.$route.path}?lang=${newVal.value}`)
          }
        },
        deep: true,
      },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import '@core/scss/vue/libs/vue-select.scss';

.rl-picker {
  width: 150px;
  position: absolute;
  top: 20px;
  right: 20px;
}
</style>
